<template>
  <v-container :class="flexSpace" fluid class="mt-5">
    <v-alert
      v-if="!allFilesFulfilled"
      max-width="674"
      border="right"
      type="info"
      elevation="2"
      rounded="xl"
      class="mx-auto mt-5 mb-5"
    >
      {{ $t('requirements.documents.alert') }}
    </v-alert>

    <v-row v-if="loaded" justify="space-around" no-gutters>
      <v-col
        cols="12"
        md="6"
        :key="`course-${i}`"
        v-for="(course, i) in getAllActiveResources"
      >
        <course
          class="mx-auto mb-3"
          :requirementId="course.requirementId"
          :resourceId="course.id"
          :initFile="getInitFile(course.requirementId)"
          :avatar="managedImg(course.avatar)"
          :link="course.link"
          :hrs="course.hrs"
          :name="course.name"
          :stats="statsById(course.requirementId)"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Course from '@/components/resources/Course'
import { mapGetters, mapActions } from 'vuex'
import { managedImg } from '@/micro/img'
import { getCovid19passRequiredFiles } from '../../store/modules/resoursesApi/fbApi'
export default {
  name: 'Courses',
  data() {
    return {
      currentUploadeFiles: [],
      loaded: false
    }
  },
  components: {
    Course
  },
  computed: {
    ...mapGetters('user', ['context', 'uid']),
    ...mapGetters('resources', ['getAllActiveResources']),
    ...mapGetters('requirements', [
      'statsById',
      'getStatsUniverse',
      'allFilesFulfilled'
    ]),
    uploadedCheck() {
      return this.uploaded ? 'mdi-check' : ''
    },
    flexSpace() {
      return this.$vuetify.breakpoint.mdAndUpd ? '' : 'ma-0 pa-0'
    },
    getInitFile() {
      return reqId => {
        const file = this.currentUploadeFiles.find(
          file => file.requirementId === reqId
        )
        return file !== undefined ? file : null
      }
    }
  },
  methods: {
    ...mapActions('resources', ['getActiveResources']),
    ...mapActions('requirements', ['showOverlay', 'hideOverlay']),
    managedImg
  },
  created() {
    this.showOverlay()
    this.getActiveResources()
    getCovid19passRequiredFiles(this.uid, this.context).then(current => {
      if (current.items && current.items.length > 0) {
        this.currentUploadeFiles = current.items
      }
      this.loaded = true
      this.hideOverlay()
    })
  }
}
</script>
