import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'

export async function getCovid19passRequiredFiles(uid, context) {
  const path = getFileMetaPath(uid, 'covid19pass', context)
  return getMetaRequiredFiles(path)
}

export async function getMetaRequiredFiles(path) {
  const fileRef = firebase.firestore().doc(path)
  const doc = await fileRef.get()
  return doc.exists ? doc.data() : []
}

export async function getInfoRequiredFiles(path) {
  const files = await (
    await firebase
      .firestore()
      .collection(path)
      .orderBy('seq')
      .get()
  ).docs.map(doc => doc.data())

  return files && files.length > 0 ? files : []
}

export function getFileInfoPath(uid, topic, reqId, { rs, ou }) {
  return `files/${rs}/${ou}/${uid}/${topic}/info/${reqId}`
}

export function getFileMetaPath(uid, topic, { rs, ou }) {
  return `files/${rs}/${ou}/${uid}/${topic}/meta`
}
